<template>
    <div align="center" v-if="is_mounted">
        <div class="text-h4">Download documenti</div>
        <br>
        <strong>Scarica le dichiarazioni Contraente da far firmare e poi manda l'email al Cliente prima di continuare</strong>
        <br><br>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-7">

                <fieldset style="margin-right: 12px; min-height: 190px;">
                    <legend><p class="text-h5">(1) - Dichiarazioni del Contraente</p></legend>
                    <div align="left">
                        Clicca sul link sotto per scaricare le dichiarazioni del Cliente
                        <strong>DA FAR FIRMARE</strong>
                        <br><br>
                        <q-list bordered separator>
                            <q-item
                                clickable
                                v-ripple
                                @click="ScaricaDichiarazioniContraente"
                            >
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica Dichiarazioni Cliente</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE E FIRMARE</strong></q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </div>
                    <br>
                </fieldset>
                <br>
            </div>

            <div class="col-12 col-md-7" v-if="otp_identificazione_enabled">

                <fieldset style="margin-right: 12px;">
                    <legend><p class="text-h5">(1A) - Certificazione recapito OTP</p></legend>

                    <template v-if="!info_cellulare.is_certificato && !otp_identificato">


                        <template v-if="is_cellulare_bloccato">
                            Il Cellulare risulta bloccato poich&egrave; &egrave; usato da pi&ugrave; Clienti.<br>
                            <div v-if="!isSede">
                                Contatta la Sede per verificare la possibilit&agrave; di sbloccare il numero di cellulare.
                            </div>
                            <br>
                            <div v-if="isSede">
                                <QQButton label="Sblocca il numero di cellulare"
                                    color="blue-grey"
                                    icon="mdi-cellphone-key"
                                    size="md"
                                    @click.native="onSbloccaCellulare"
                                />
                                <br><br>
                                <strong>ATTENZIONE:</strong> Il numero di cellulare sar&agrave; sbloccato solo per questo preventivo.
                            </div>
                        </template>



                        <template v-if="!is_cellulare_bloccato">
                            <div align="left">
                                Questa procedura serve per stabilire che il numero di cellulare appartiene al Contraente. Per fare questa verifica esegui i seguenti step:
                                <ol>
                                    <li>Clicca sul pulsante "Effettua la certificazione della SIM"</li>
                                    <li>Clicca sul pulsante "INVIA SMS" che invia un codice alfanumerico al cellulare del Contraente chiamato "Codice OTP"</li>
                                    <li>Vengono attivate alcune domande che devi Confermare</li>
                                    <li>Si attiva il campo dove devi inserire il codice OTP che ti comunica il Contraente</li>
                                    <li>Clicca sul pulsante "VERIFICA" per accertare che il codice OTP che hai inserito sia lo stesso che ha ricevuto il Contraente</li>
                                    <li>Se il codice &egrave; corretto, viene comunicato il successo, altrimenti viene restituito un errore e la procedura va ripetuta</li>
                                    <li>Conferma l'ultima domanda dove l'Intermediario certifica che il cellulare appartiene al Contraente</li>
                                </ol>
                            </div>

                            <QQButton label="Effettua la certificazione della SIM"
                                color="blue-grey"
                                icon="mdi-email-check-outline"
                                size="md"
                                @click.native="onVisualizzaModaleOtp"
                            />

                            <q-dialog v-model="visualizza_finestra_otp" persistent>
                                <q-card style="width: 600px;">
                                    <q-card-section>
                                    <div class="text-h6">Certificazione recapito OTP</div>
                                    </q-card-section>

                                    <q-card-section class="q-pt-none">

                                        <QQOtp
                                            title=""
                                            :cellulare="cellulare_cliente"
                                            :guid_cliente="formPreventivo.IDCliente"
                                            v-model="otp_identificato"
                                            certificazione
                                            enable_change_num
                                        />
                                    <!-- Aggiungi certificazione -->
                                    </q-card-section>

                                    <q-card-actions align="right">
                                        <q-btn label="ESCI" color="secondary" v-close-popup />
                                    </q-card-actions>
                                </q-card>
                            </q-dialog>

                        </template>

                    </template>

                    <template v-if="info_cellulare.is_certificato || otp_identificato">
                        <h6>Il numero di cellulare risulta certificato come valido</h6>
                    </template>

                </fieldset>
                <br>

            </div>

            <div class="col-12 col-md-7">

                <fieldset style="margin-right: 12px; min-height: 240px;">
                    <legend><p class="text-h5">(2) - Invia documenti al Cliente per Email</p></legend>
                    <p>Dopo aver scaricato le dichiarazioni del Cliente, inviagli una Email. Verifica
                        che l'indirizzo sotto sia corretto prima di continuare.
                        <strong>Se è errato, torna indietro e modificalo.</strong>
                    </p>
                    <p>Email Cliente: <strong style="font-size: 16px;">{{ getEmailCliente }}</strong></p>
                    <div class="col-12 col-md-4" align="center">
                        <QQButton label="Modifica Email" color="blue-grey" icon="undo" size="md"
                            @click.native="onIndietroClicked"
                        />
                        <QQButton label="Manda Email al Cliente"
                            color="blue-grey"
                            icon="mdi-email-check-outline"
                            size="md"
                            :disabled="isEmailDisabled"
                            @click.native="onInviaEmailClicked"
                        />
                    </div>
                    <br>
                </fieldset>
                <br>
            </div>

            <div class="col-12 col-md-7" align="left">

                <fieldset style="margin-right: 12px; min-height: 190px;">
                    <legend><p class="text-h5">(3) - Altre Informazioni da leggere </p></legend>

                    <div align="left">
                        Clicca sul link sotto per scaricare i documenti
                        da far leggere al Contraente
                        <br><br>
                        <q-list bordered separator>
                            <q-item clickable v-ripple @click="ScaricaCondizioniPreContrattuali">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica DOC1 - Documentazione Pre-Contrattuale</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaPrivacy">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica DOC2 - Informativa sulla Privacy</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaReclami">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica DOC3 - Gestione dei Reclami</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaRaccomandazionePersonalizzata" v-if="haConsulenza">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica DOC4 - Raccomandazione Personalizzata</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaElencoCompagnie">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica DOC5 - Elenco Compagnie</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaPOGAby" v-if="isAbyBroker">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica DOC6 - POG di Aby Broker</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaFilieraDistributiva">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica Filiera distributiva e Pagamento del premio</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaMercatoRiferimento">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica Mercato di Riferimento</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaDichiarazioniCoerenza">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica Dichiarazione di Coerenza</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item clickable v-ripple @click="ScaricaElencoPreventivi">
                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>
                                <q-item-section>
                                    <strong>Scarica Elenco Preventivi</strong>
                                </q-item-section>
                                <q-item-section side>
                                    <q-item-label caption><strong style="font-size: 1.4em;">LEGGERE</strong></q-item-label>
                                </q-item-section>
                            </q-item>


                        </q-list>
                    </div>
                    <br>
                </fieldset>
                <br>
            </div>

            <div class="col-12 col-md-7" align="left">
                <fieldset style="margin-right: 12px; min-height: 240px;">
                    <legend><p class="text-h5">(4) - Documentazione Proposte Selezionate</p></legend>

                    <q-list bordered separator>
                        <q-item clickable v-ripple
                            v-for="(documento,index) in elenco_documenti"
                            v-bind:key="index"
                             @click.native="ScaricaDocumentazioneProposta(documento)">
                            <q-item-section avatar>
                                <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                            </q-item-section>
                            <q-item-section>
                                <strong>{{documento.descrizione}}</strong>
                            </q-item-section>
                            <q-item-section side>
                                <q-item-label caption><strong style="font-size: 1.4em;">{{transcodificaAzioneDocumento(documento.azione)}}</strong></q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>

                </fieldset>
                <br>
            </div>
        </div>

        <br><hr>
        <div class="row justify-center">
            <div class="col-12 col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onIndietroClicked"
                />
            </div>

            <div class="col-12 col-md-4" align="center">
                <QQButton label="ANNULLA EMISSIONE" color="blue-grey" icon="mdi-close-octagon" size="md"
                    @click.native="gotoStart()"
                />
            </div>

            <div class="col-12 col-md-4" align="center">
                <QQButton label="AVANTI" color="blue-grey" icon="mdi-arrow-right-bold" size="md"

                    :disabled="isEmailDisabled"
                    @click.native="onAvantiClicked"
                />
            </div>
        </div>
        <br><br><br><br><br>
    </div>
</template>

<script>
    import axios from 'axios';
    import QQButton from "@/components/QQButton.vue";
    import QQOtp from "@/components/otp/QQOtp.vue";
    //import QQOtpModal from "@/components/otp/QQOtpModal.vue";
    import { mapState, mapActions } from "vuex";
    import { exportFile } from 'quasar';
    import commonLib from "@/libs/commonLib";
    import policy from "@/libs/policy";

    export default {
        name: "DownloadDocumenti",
        data() {
            return {
                questionario_is_valid: false,
                cellulare_cliente: "",
                urlDocumentoPreContrattuale: "",
                urlDichiarazioniContraente: "",
                urlReclami: "",
                elenco_documenti: [],
                is_loading: false,
                risposte: [],
                otp_identificato: false,
                indice_step: 1,
                otp_identificazione_enabled: false,
                visualizza_finestra_otp: false,
                info_cellulare: {},
                is_mounted: false,
                is_cellulare_bloccato: false
            }
        },
        components: {
            QQButton,
            QQOtp,
            //QQOtpModal
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo
            }),
            isSede() {
                return policy.isSede();
            },
            getEmailCliente() {
                return this.$store.getters["gestioneClienti/getEmailCliente"];
            },
            isEmailDisabled() {
                // if (this.emailBoxSender) return false;

                var is_email_disabled = this.$store.getters["gestioneClienti/getEmailDisabled"];
                if (this.info_cellulare.is_certificato || this.otp_identificato) is_email_disabled = false;
                return is_email_disabled;
            },
            NavDisabled() {
                var is_nav_disabled = this.$store.getters["gestioneClienti/getNavDisabled"];
                return is_nav_disabled;
            },
            isAbyBroker() {
                console.log("formPreventivo:",this.formPreventivo);
                return this.formPreventivo.unita_operativa.is_abybroker;
            },
            prodotti_attivi() {
                return this.formPreventivo.preventivo.prodotti.filter(p => {
                    return p.is_checked;
                });
            },
            haConsulenza() {
                var consulenza = 0.00;

                for (var i=0;i<this.prodotti_attivi.length;i++) {
                    var prodotto = this.prodotti_attivi[i];
                    consulenza += parseFloat(prodotto.consulenza);
                    // Aggiunge anche i diritti aby
                    var proposta = prodotto.proposte.filter(p => {
                        return p.is_checked;
                    });

                    consulenza += parseFloat(proposta[0].diritti_aby);
                }

                return (consulenza > 0);
            }
        },
        methods: {
            /*async isEmailBoxSender() {
                var result = await this.isEmailBoxInformativoSender(this.formPreventivo.preventivo.id);
                return result.is_sender;
            },*/
            async onSbloccaCellulare() {

                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No, per niente",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, lo voglio"
                    },
                    title: 'Attenzione',
                    message: 'Vuoi davvero sbloccare il numero di cellulare '+this.cellulare_cliente+'?'
                }).onOk(async () => {
                    await this.sbloccaCellulareTroppoUsato(this.formPreventivo.preventivo.id);
                    this.is_cellulare_bloccato = false;
                }).onCancel(() => {

                }).onDismiss(() => {

                })


            },
            onVisualizzaModaleOtp() {
                this.visualizza_finestra_otp = true;
            },
            transcodificaAzioneDocumento(azione) {
                switch(azione) {
                    case "COMPILARE_E_FIRMARE": return "COMPILARE E FIRMARE";
                    case "LEGGERE": return "LEGGERE";
                }

                return "Scaricare";
            },
            async onInviaEmailClicked() {
                var id_preventivo = this.formPreventivo.preventivo.id;
                this.is_loading = true;

                // prima di inviare le email, verifica che i documenti principali siano presenti
                if (this.urlDichiarazioniContraente === "") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Devi scaricare il documento con le dichiarazioni del Contraente da far firmare prima di inviare una email al Cliente'
                    })

                    this.is_loading = false;
                    return;
                }

            /*    if (this.urlDocumentoPreContrattuale === "") {
                    await this.GeneraDocumentoPreContrattuale();
                }

                if (this.urlDichiarazioniContraente === "") {
                    await this.GeneraDichiarazioniContraente();
                } */

                //this.GeneraDocumentoPreContrattuale();
                //this.GeneraDichiarazioniContraente();
                this.GeneraElencoCompagnie();
                this.GeneraElencoPreventivi();
                this.GeneraMercatoRiferimento();
                this.GeneraPogAby();
                this.GeneraPrivacy();
                this.GeneraRaccomandazionepersonalizzata();
                this.GeneraReclami();
                this.GeneraFilieraDistributiva();
                this.GeneraDichiarazioniCoerenza();

                await this.sendEmailToClient(id_preventivo);
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'Ho mandato l`Email al Cliente'
                });
                //this.isNavDisabled = false;

                this.$store.dispatch("gestioneClienti/setNavDisabled",false);

                this.is_loading = false;
            },

            async GeneraDocumentoPreContrattuale() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlDocumentoPreContrattuale(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraReclami() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlReclami(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraElencoCompagnie() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlElencoCompagnie(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraRaccomandazionepersonalizzata() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlRaccomandazionePersonalizzata(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraPrivacy() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlPrivacy(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraPogAby() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlPOGAby(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraMercatoRiferimento() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlMercatoRiferimento(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraElencoPreventivi() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlElencoPreventivi(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraFilieraDistributiva() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlFilieraDistribuitiva(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async GeneraDichiarazioniCoerenza() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlDichiarazioniCoerenza(id);
                this.urlDocumentoPreContrattuale = myurl;
                return myurl;
            },
            async ScaricaCondizioniPreContrattuali() {
                this.is_loading = true;
                var url_pdf_precontrattuale = this.urlDocumentoPreContrattuale;
                this.is_loading = true;

                //if(myurl === "") {
                    url_pdf_precontrattuale = await this.GeneraDocumentoPreContrattuale();
               // }

                await axios({
                    url:url_pdf_precontrattuale,
                    method:'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    let status = exportFile("documentoPreContrattuale.pdf", response.data);
                    if (status === true) {
                        //console.log('Generato documentoPreContrattuale.pdf', status);
                    } else {
                        // browser denied it
                        //console.error('Errore generazione documentoPreContrattuale.pdf', status);
                    }

                });

                this.is_loading = false;
            },
            async ScaricaReclami() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlReclami(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("reclami.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },
            async ScaricaElencoCompagnie() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlElencoCompagnie(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("compagnie.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },
            async ScaricaRaccomandazionePersonalizzata() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlRaccomandazionePersonalizzata(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("raccomandazione_personalizzata.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },
            async ScaricaPrivacy() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlPrivacy(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("privacy.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },
            async ScaricaPOGAby() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlPOGAby(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("pog_aby.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },
            async ScaricaFilieraDistributiva() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlFilieraDistribuitiva(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("filiera_distributiva.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },
            async ScaricaMercatoRiferimento() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlMercatoRiferimento(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("mercato_riferimento.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },
            async ScaricaElencoPreventivi() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlElencoPreventivi(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("elenco_preventivi.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },
            async ScaricaDichiarazioniCoerenza() {
                this.is_loading = true;
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlDichiarazioniCoerenza(id);
                this.urlReclami = myurl;

                await axios({
                    url: myurl,
                    method: 'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    //let status =
                    exportFile("dichiarazione_coerenza.pdf", response.data);
                });

                this.is_loading = false;

                return myurl;
            },

            async GeneraDichiarazioniContraente() {
                var id = this.formPreventivo.preventivo.id;
                var myurl = await this.fetchUrlDichiarazioniContraente(id);
                this.urlDichiarazioniContraente = myurl;

                // Scarica anche la documentazione pre-contrattuale
                // this.ScaricaCondizioniPreContrattuali();
                this.GeneraDocumentoPreContrattuale();

                return myurl;
            },

            async ScaricaDichiarazioniContraente() {
                var url_pdf_dichiarazioni = this.urlDichiarazioniContraente;
                this.is_loading = true;

                //if(myurl === "") {
                    url_pdf_dichiarazioni = await this.GeneraDichiarazioniContraente();
                //}

                await axios({
                    url:url_pdf_dichiarazioni,
                    method:'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    const status = exportFile('dichiarazioniContraente.pdf', response.data)
                    if (status === true) {
                        console.log('Generato documentoPreContrattuale.pdf', status);
                    } else {
                        // browser denied it
                        console.error('Errore generazione dichiarazioniContraente.pdf', status);
                    }
                });

                //this.isEmailDisabled = false;
                this.$store.dispatch("gestioneClienti/setEmailDisabled",false);

                this.is_loading = false;
            },
            async ScaricaDocumentazioneProposta(documento) {
                var url = documento.path;
                var nome = documento.id;

                this.is_loading = true;

                await axios({
                     url:url,
                     method:'GET',
                     responseType: 'blob',
                    headers:{ Authorization:""}
                 })
                 .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute('download', nome+".pdf");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                 });

                this.is_loading = false;
            },
            ...mapActions({
                fetchUrlDocumentoPreContrattuale: "gestioneDocumenti/fetchUrlDocumentoPreContrattuale",
                fetchUrlDichiarazioniContraente: "gestioneDocumenti/fetchUrlDichiarazioniContraente",
                fetchUrlReclami: "gestioneDocumenti/fetchUrlReclami",
                fetchUrlElencoCompagnie: "gestioneDocumenti/fetchUrlElencoCompagnie",
                fetchUrlRaccomandazionePersonalizzata: "gestioneDocumenti/fetchUrlRaccomandazionePersonalizzata",
                fetchUrlPrivacy: "gestioneDocumenti/fetchUrlPrivacy",
                fetchUrlPOGAby: "gestioneDocumenti/fetchUrlPOGAby",
                fetchUrlFilieraDistribuitiva: "gestioneDocumenti/fetchUrlFilieraDistribuitiva",
                fetchUrlMercatoRiferimento: "gestioneDocumenti/fetchUrlMercatoRiferimento",
                fetchUrlElencoPreventivi: "gestioneDocumenti/fetchUrlElencoPreventivi",
                fetchUrlDichiarazioniCoerenza: "gestioneDocumenti/fetchUrlDichiarazioniCoerenza",

                // fetchUploadUrl: "gestioneDocumenti/fetchUploadUrl",
                fetchElencoDocumentiTariffa: "gestioneDocumenti/fetchElencoDocumentiTariffa",
                // fetchElencoDocumenti: "gestioneDocumenti/fetchElencoDocumenti",
                sendEmailToClient: "formPreventivo/sendEmailToClient",
                fetchAggiornaEmailCliente: "gestioneClienti/fetchAggiornaEmailCliente",
                fetchElencoPreventivi: "formPreventivo/fetchElencoPreventivi",

                isOtpIdentificazioneEnabled: "gestioneOTP/isOtpIdentificazioneEnabled",
                infoCellulare: "gestioneOTP/infoCellulare",
                sbloccaCellulareTroppoUsato: "gestioneOTP/sbloccaCellulareTroppoUsato"
            }),
            async onAvantiClicked() {
                //await this.fetchElencoDocumenti();
                this.$router.push({name : "Preventivi.GestioneDocumenti"});
            },
            onIndietroClicked() {
                this.formPreventivo.step = 8;
                this.$router.push({name : "Preventivi.FormPreventivo", params: { area: this.area }});
            },
            async getElencoDocumentiTariffa(id_tariffa) {
                var create_at = commonLib.castDateToIso(this.formPreventivo.preventivo.create_at);
                var idTariffa = id_tariffa;
                var dati = {
                    id_tariffa: idTariffa,
                    data_create_at: create_at
                }
                var elenco =  await this.fetchElencoDocumentiTariffa(dati);

                console.log("elenco.documenti:",elenco.documenti);

                return elenco.documenti;
            },
            async gotoStart() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "Non annullare",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si"
                    },
                    title: 'Attenzione',
                    message: 'Vuoi davvero annullare il preventivo?'
                }).onOk(() => {
                    this.fetchElencoPreventivi("professionisti");
                    this.$router.push({name : "Preventivi"});
                });
            },
            async initialize() {
                var prodotti = this.formPreventivo.preventivo.prodotti;
                this.elenco_documenti = [];
                this.is_loading = false;
                this.otp_identificato = false;
                this.indice_step = 1;

                var cellofono = this.formPreventivo.dati_cliente[0].domande.find(d => {
                    return d.name === "cellulare";
                });

                this.visualizza_finestra_otp = false;
                this.cellulare_cliente = cellofono.value;
                console.log("cellulare cliente : ",this.cellulare_cliente);

                this.info_cellulare = await this.infoCellulare({
                    numero_cellulare: this.cellulare_cliente,
                    guidCliente: this.formPreventivo.IDCliente,
                    guidPreventivo: this.formPreventivo.preventivo.id
                });


                this.otp_identificato = this.info_cellulare.is_certificato;
                this.is_cellulare_bloccato = this.formPreventivo.cellulare_bloccato;
                if (this.is_cellulare_bloccato === -1) {
                    this.is_cellulare_bloccato = 0;
                    if (this.info_cellulare.is_bloccato) {
                        this.is_cellulare_bloccato = 1;
                    }

                    this.formPreventivo.cellulare_bloccato = this.is_cellulare_bloccato;
                }
                this.otp_identificazione_enabled = await this.isOtpIdentificazioneEnabled();

                console.log("stato Cellulare:",this.is_cellulare_bloccato);
                console.log("otp_identificazione_enabled:",this.otp_identificazione_enabled);

                window.scrollTo(0,0);

                prodotti.forEach(prodotto => {
                    if (!prodotto.is_checked) {
                        return;
                    }

                    prodotto.proposte.forEach(async proposta => {
                        if (!proposta.is_checked) {
                            return;
                        }
                        var documenti = await this.getElencoDocumentiTariffa(proposta.tariffa);

                        console.log("documenti:",documenti,proposta.tariffa);

                        documenti.forEach(documento => {
                            this.elenco_documenti.push(documento);
                        });

                    });
                });

            /*    this.info_cellulare = await this.infoCellulare({
                    numero_cellulare: this.cellulare_cliente,
                    guidCliente: this.formPreventivo.IDCliente,
                    guidPreventivo: this.formPreventivo.preventivo.id
                });
                console.log("stop"); */

                this.otp_identificato = this.info_cellulare.is_certificato;

                console.log("info_cellofono:",this.info_cellulare);
            }

        },
        async mounted() {
            this.is_mounted = false;
            this.initialize();

            //console.log("DownloadDocumenti->formPreventivo:",this.formPreventivo);
            window.scrollTo(0,0);
            this.is_mounted = true;
        },
        activated() {
            //this.is_loading = true;
            this.initialize();

            window.scrollTo(0,0);
        }
    }
</script>
